import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'  
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import LoginForm from './components/LoginPage';
import LandingPage from './components/LandingPage';

 
const routing = (  
    <Router>  
      <div>  
        <Routes>
		<Route exact path="/" element={<LandingPage />} />  
        {/* <Route exact path="/login" element={<LoginForm />} />   */}
        </Routes>
      </div>  
    </Router>  
  )  
ReactDOM.render(routing, document.getElementById('root'));
reportWebVitals();
