import React from 'react';
import ReactDOM from 'react-dom';
import '../index.css';
import reportWebVitals from '../reportWebVitals';
import CircularProgress from "@material-ui/core/CircularProgress";
import LandingPage from './LandingPage';
import NewMap from './NewMap';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Modal} from 'react-bootstrap';
import { Slider } from '@material-ui/core';



// --------------- The My Fields View (Shows all the fields and NDVI change charts) ---------------
class MyFields extends React.Component { 
	constructor(props) {
		super(props);
		this.state = {
			show: this.props.visibleNow,
			showSpinner: false,
			username: this.props.username,
			visualData: this.props.visualData,
			chartDates: this.props.chartDates,
			chartScores: this.props.chartScores,
			inProgress: this.props.inProgress,
			modalTitle: this.props.modalTitle,
			modalBody: this.props.modalBody,
			spinnerModalBody: '',
			nicknames: this.props.nicknames,
			howManyFields: Array(this.props.visualData.length).fill(0),
		}
		this.handleLogout = this.handleLogout.bind(this);
		this.handleAddField = this.handleAddField.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleLogout() {
		ReactDOM.render(
			<LandingPage/>,
			document.getElementById('root')
		);
	}

	handleAddField() {
		ReactDOM.render(
			<NewMap username={this.state.username}/>,
			document.getElementById('root')
		);
	}

	handleClose(event) {
		this.setState({show: false});
	}

  
	render() {

		const visualData = this.state.visualData;
		const chartDates = this.state.chartDates;
		const chartScores = this.state.chartScores;
		const inProgress = this.state.inProgress;
		const nicknames = this.state.nicknames;

		const forMarks = []
		for (var m = 0; m < chartDates.length; m++) {
			console.log(chartDates[m])
			var theseMarks = []
			for (var mm = 1; mm < chartDates[m].length+1; mm++) {
				theseMarks.push(chartDates[m][mm-1])
			}
			forMarks.push(theseMarks)
		}

		console.log(visualData)
		console.log("thats the vd")

	
		return (
			<Container>
				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.modalTitle}</Modal.Title>
					</Modal.Header>
						<Modal.Body>
							{this.state.modalBody}
						</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showSpinner} style={{ content: {borderRadius: '10px'}}}>
					<Modal.Body>
						<Container>
							<Row style={{margin: 10}}>
								<Col>
									<div style={{display: 'flex', justifyContent: 'center'}}>
										{this.state.spinnerModalBody}
									</div>
								</Col>
							</Row>
							<Row style={{margin: 10, padding: 10}}>
								<Col>
									<div style={{display: 'flex', justifyContent: 'center'}}>
										<CircularProgress size={30} />	
									</div>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
				<div className="title-class">
					<p>Welcome {this.props.username}!</p> 
					<p>Click on "Add a field" to add fields to be analyzed.  
					Once chosen, fields will appear below, along with their NDVI overlays.</p>
					{ (nicknames[0] == "Example field") ?
						(
							<p>The field below is an example.  Scroll right with the scroller below the image,
							and you'll be able to see crop health change over time.
							</p>
						) : <></>
					}
				</div>
				<Row>
					<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
						<button className="add-logout-button" onClick={this.handleAddField}>
							Add a field
						</button>
					</Col>
				</Row>
				<Row>
					<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
						<button className="add-logout-button" onClick={this.handleLogout}>
							Logout
						</button>
					</Col>
				</Row>
				
				<Row>

					
					{
						visualData ? (
							visualData.map((fieldArray, index) => 
							<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
							<Card className='field-card'>
								<h2>{nicknames[index]}</h2>
								<h3>{forMarks[index][this.state.howManyFields[index]]}</h3>
								<img src={fieldArray[this.state.howManyFields[index]]} style={{width: 400, height: 400 }} />
								
									<Slider
										aria-label="Temperature"
										defaultValue={0}
										valueLabelDisplay="off"
										step={1}
										min={0}
										marks={forMarks[index]}
										max={fieldArray.length-1}
										onChange={ (_e, val) => {
											// 1. Make a shallow copy of the items
											let fieldArray = [...this.state.howManyFields];
											// 2. Make a shallow copy of the item you want to mutate
											let fieldInQuestion = {...fieldArray[index]};
											// 3. Replace the property you're intested in
											fieldInQuestion = val;
											// 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
											fieldArray[index] = fieldInQuestion;
											// 5. Set the state to our new copy
											this.setState({howManyFields: fieldArray});
											}
										}  
										/>
										<button className="delete-button" onClick={() => {
												console.log(nicknames[index])
												this.setState({showSpinner: true})
												this.setState({spinnerModalBody: 'Deleting the field'})
												fetch('https://us-central1-hotaru-kanri.cloudfunctions.net/rvm_cloud_delete_field', {
													method: 'POST',
													headers: {
														'Content-Type':'application/json'
													},
													body: JSON.stringify({
														username: this.state.username, 
														nickname: nicknames[index], 
													 })
												}).then(response => response.json())
												.then(response => {
													this.setState({showSpinner: false})
													this.setState({visualData: response.visualData})
													this.setState({chartDates: response.chartDates})
													this.setState({chartScores: response.chartScores})
													this.setState({inProgress: response.inProgress})
													this.setState({show: true})
													this.setState({modalTitle: "Field has been deleted"})
													this.setState({modalBody: "The field has been deleted."})
													this.setState({nicknames: response.nicknames})
												})
											}
										}>Delete this field
										</button>
									
									{/* <LineChart nickname={nicknames[index]} labels={chartDates[index]} data={chartScores[index]}/> */}
								</Card>
								</Col>)
						) : <div>No fields yet</div>
    				}
					
				</Row>
				
			</Container>
		);
	}
} export default MyFields
