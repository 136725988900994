import React from 'react';
import ReactDOM from 'react-dom';
import '../index.css';
import '../style.css';
import logo from '../logo.jpeg';
import reportWebVitals from '../reportWebVitals';
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginForm from './LoginPage';
import NewMap from './NewMap';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


 









// --------------- The Landing Page (Where the user goes first) ---------------
class LandingPage extends React.Component { 
	constructor(props) {
		super(props);
		this.state = {
			
		}
		this.handleLogin = this.handleLogin.bind(this);
	}

	handleLogin() {
		ReactDOM.render(
			<LoginForm/>,
			document.getElementById('root')
		);
	}

  
	render() {
	
		return (
			<Container>



                <div className="body-wrap boxed-container">
                    

                    <main>
                    <section className="cta section">
                            <div className="container-sm">
                                <div className="cta-inner section-inner">
                                    <div className="cta-header text-center">
                                    <img src="https://storage.googleapis.com/rvm_ndvi_images/logo.jpeg" style={{width: 400, alignSelf: 'center', borderRadius: 50, borderWidth: 5, borderColor: 'black', padding: 20, margin: 'auto' }}/>
                                        
                                                {/* <div className="hero-inner">
                                                    <div className="hero-copy" style={{justifyContent: 'center'}}> */}
                                                    
                                                        <p className="hero-paragraph">Track the thickness and health of vegetation, on any area of land, anywhere in the world.</p>
                                                    {/* </div>
                                                </div> */}
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/OTQphNxbuxI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                </div>
                                
                                
                        
                        </section>

                        <section className="features section">
                            <div className="container">
                                <div className="features-inner section-inner">
                                    <div className="features-header text-left">
                                        <div className="container-sm" style={{alignContent: 'center' }}>
                                            <h2 className="section-title mt-0">Simple Agtech Powered by Google Earth Engine</h2>
                                            <p className="section-paragraph">Crop Watcher is a simple system, really, that makes use of Sentinel-2 satellite imagery, provided by the European Space Agency via Google Earth Engine.</p>
                                            <p className="section-paragraph">Users can choose the areas of land they want to monitor, and Crop Watcher retrieves all images of the area in question (between  a start date and end date set by the user).</p>
                                            <p className="section-paragraph">Analyzing the near-infrared and red bands of the images, Crop Watcher delivers data about crop health over time, via a simple dashboard.</p>

                                        </div>
                                    </div>
                                    <div className="features-wrap">
                                        <div className="feature is-revealing">
                                            <div className="feature-inner">
                                                
                                                <div className="feature-content">
                                                    <h3 className="feature-title mt-0">Indispensable for farmers</h3>
                                                    <p className="text-sm mb-0">Crop watcher is great for farmers who have large fields  that would be impractical to monitor with the naked eye.  Also, farmers who have many small fields scattered over a large area can benefit from using Crop Watcher (fields should be 10 meters square  or greater, due to satellite image resolution constraints).</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature is-revealing">
                                            <div className="feature-inner">
                                                
                                                <div className="feature-content">
                                                    <h3 className="feature-title mt-0">Great for monitoring recreational areas</h3>
                                                    <p className="text-sm mb-0">Crop Watcher is ideal for municipalities with many large parks and recreation areas.  The health and quality of grassy areas/forested areas can be easily monitored, without having to visit the site.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature is-revealing">
                                            <div className="feature-inner">
                                                <div className="feature-content">
                                                    <h3 className="feature-title mt-0">Timely, too</h3>
                                                    <p className="text-sm mb-0">The Sentinel-2 satellites fly over nearly every area of the globe at 5-day intervals, meaning that you can track the health of your land with very good temporal resolution (except in areas with frequent cloud cover).</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="cta section">
                            <div className="container-sm">
                                <div className="cta-inner section-inner">
                                    <div className="cta-header text-center">
                                        <h2 className="section-title mt-0">Log in now and check it out!</h2>
                                        <p className="section-paragraph">All that is required is an email address (only used for sending one-time "Analysis Complete" messages).  Log in, choose the area you want to monitor, and the data will be available minutes later (depending on the time window chosen).</p>
                                        <LoginForm />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>    
                    </div>

                <script src="dist/js/main.min.js"></script>


				
			</Container>
		);
	}
} export default LandingPage
