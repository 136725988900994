import React from 'react';
import ReactDOM from 'react-dom';
import '../index.css';
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import MyFields from './MyFields';
 




// ------- Gateway to the app: The Login Page ----------------------
class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		show: this.props.visibleNow,
		showSpinner: false,
		spinnerModalBody: '',
		modalTitle: this.props.modalTitle,
		modalBody: this.props.modalTitle,
		username: '',
		password: ''
	};

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
	this.handleCreateClick = this.handleCreateClick.bind(this);
	this.handleLoginClick = this.handleLoginClick.bind(this);
	this.handleClose = this.handleClose.bind(this);
  }

 
	handleClose() {
		this.setState({show: false});
	}
  

	handleUsernameChange(event) {
		this.setState({username: event.target.value});
	}

	handlePasswordChange(event) {
		this.setState({password: event.target.value});
	}

	handleLoginClick(event) {
		if ((this.state.username === '') || (this.state.password === '')) {
			return
		}
		event.preventDefault();
		this.setState({showSpinner: true})
		this.setState({spinnerModalBody: "Logging in..."})
		fetch('https://us-central1-hotaru-kanri.cloudfunctions.net/rvm_cloud_login_or_create_account', {  

		 	method: 'POST',
		 	headers: {
		 		'Content-Type':'application/json'
		 	},
		 	body: JSON.stringify({which: "login", username: this.state.username, password: this.state.password })
			
		 }).then(response => response.json())
		.then(response => {		

			// ---- If successful
			if (response.success === "no problem") {
				ReactDOM.render(
					<MyFields 
					visualData={response.visualData} 
					chartDates={response.chartDates}
					chartScores={response.chartScores} 
					inProgress={response.inProgress}
					visibleNow={false} 
					username={this.state.username}
					nicknames={response.nicknames}
					/>,
					document.getElementById('root')
				);
			}

			// ---- If not successful
			if (response.success === "bad credentials") {
				this.setState({show: true})
				this.setState({modalTitle: "Bad credentials"})
				this.setState({modalBody: "Either your username or password is incorrect. Please try again."})
			}
			this.setState({showSpinner: false})
		});
	}
  

	handleCreateClick(event) {

		event.preventDefault();
		if ((this.state.username === '') || (this.state.password === '')) {
			return
		}
		this.setState({showSpinner: true})
		this.setState({spinnerModalBody: "Attempting to create account..."})

		fetch('https://us-central1-hotaru-kanri.cloudfunctions.net/rvm_cloud_login_or_create_account', {

		 	method: 'POST',
		 	headers: {
		 		'Content-Type':'application/json'
		 	},
		 	body: JSON.stringify({which: "create", username: this.state.username, password: this.state.password })
			
		 }).then(response => response.json())
		.then(response => {		
			// ---- If new account successfully created
			if (response.success === "account created") {
				ReactDOM.render(
					<MyFields 
					visualData={[[`https://maps.google.com/maps/api/staticmap?sensor=false&center=37.0305985992358,-120.28934648035954&zoom=16&size=400x400&key=${process.env.REACT_APP_MAPS_API_KEY}&maptype=hybrid`,"https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-05-06.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-05-11.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-05-26.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-05-31.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-06-05.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-06-10.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-06-15.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-06-20.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-06-25.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-06-30.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-07-05.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-07-10.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-07-15.png","https://storage.googleapis.com/rvm_ndvi_images/t_Braga Two_2021-07-20.png"]]}
					chartDates={[["Reference image","2021-5-5","2021-05-06","2021-5-21","2021-05-26","2021-05-31","2021-06-05","2021-06-10","2021-06-15","2021-06-20","2021-06-25","2021-06-30","2021-07-05","2021-07-10","2021-07-15","2021-07-20"]]}
					chartScores={response.chartScores} 
					inProgress={response.inProgress}
					visibleNow={true} 
					modalTitle={"Account created"} 
					modalBody={"Your account has been created."}
					username={this.state.username}
					nicknames={["Example field"]}
					/>,
					document.getElementById('root')
				);
			}

			// ----- If new account not successfully created
			if (response.success === "username taken") {
				this.setState({show: true})
				this.setState({modalTitle: "Username taken"})
				this.setState({modalBody: "That username is already in use currently. Please choose a different username."})
			}
			this.setState({showSpinner: false})
		});
	}
	

	
  	render() {
		
		return (
			<Container>
				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.modalTitle}</Modal.Title>
					</Modal.Header>
						<Modal.Body>
							{this.state.modalBody}
						</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={this.handleClose}>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showSpinner} style={{ content: {borderRadius: '10px'}}}>
					<Modal.Body>
						<Container>
							<Row style={{margin: 10}}>
								<Col>
									<div style={{display: 'flex', justifyContent: 'center'}}>
										{this.state.spinnerModalBody}
									</div>
								</Col>
							</Row>
							<Row style={{margin: 10, padding: 10}}>
								<Col>
									<div style={{display: 'flex', justifyContent: 'center'}}>
										<CircularProgress size={30} />	
									</div>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
				<Row>
					<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
						<span  className="title-class">Email:</span> <FormControl type="email" value={this.state.usernameValue} onChange={this.handleUsernameChange}/>
					</Col>
				</Row>
				<Row>
					<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
						<span  className="title-class">Password:</span> <FormControl type="password" value={this.state.passwordValue} onChange={this.handlePasswordChange}/>
					</Col>
				</Row>
				<Row style={{ marginTop: 5, marginBottom: 5}}>
					<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
						<button className="add-logout-button" onClick={this.handleLoginClick}>
						Login</button>
					</Col>
				</Row>
				<Row style={{ marginTop: 5, marginBottom: 5}}>
					<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
						<button className="add-logout-button" onClick={this.handleCreateClick}>
						Create an account with this info</button>					
					</Col>
				</Row>
			</Container>
		);
	}
} export default LoginForm