import React, { Component } from 'react';
import { GoogleMap, LoadScript, MapContext, useJsApiLoader } from '@react-google-maps/api';
import ReactDOM from 'react-dom';
import '../index.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import MyFields from './MyFields';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';

import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
 

const containerStyle = {
	width: '400px',
	height: '400px'
  };

// --------------- The Add a Field View (Allows users to add fields) ---------------------
class NewMap extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: this.props.username,
			mapRef: React.createRef(),
			map: {},
			center: {
				lat: -3.745,
				lng: -38.523
			},
			address: "",
			nickname: "",
			startDate: "",
			endDate: "",
			newCenter: "",
			zoom: "",
			neLat: "",
			swLat: "",
			neLong: "",
			swLong: "",
			show: false,
			showSpinner: false,
			spinnerModalBody: '',
			modalTitle: "",
			modalBody: ""
		}
		this.handleAddressChange = this.handleAddressChange.bind(this);
		this.handleFindAddress = this.handleFindAddress.bind(this);
		this.handleNicknameChange = this.handleNicknameChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleAddThisField = this.handleAddThisField.bind(this);
		this.handleCenterChange = this.handleCenterChange.bind(this)
		this.handleZoomChange = this.handleZoomChange.bind(this);
		this.setStartDate = this.setStartDate.bind(this);
		this.setEndDate = this.setEndDate.bind(this);
	}

	handleClose() {
		this.setState({show: false})
	}

	handleAddressChange(event) {
		this.setState({address: event.target.value});
	}

	handleNicknameChange(event) {
		this.setState({nickname: event.target.value});
	}

	handleFindAddress() {
		this.setState({showSpinner: true})
		this.setState({spinnerModalBody: "Finding that address..."})
		fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(this.state.address)}&key=${process.env.REACT_APP_MAPS_API_KEY}`, {
			method: 'POST',
		}).then(response => response.json())
		.then(response => {
			this.setState({center: response.results[0].geometry.location})
			this.setState({showSpinner: false})
		})
		.catch(error => {
			this.setState({show: true})
			this.setState({modalTitle: "Invalid address"})
			this.setState({modalBody: "Couldn't find the address, please try again."})
			this.setState({showSpinner: false})
		})		
	}

    handleMapLoad = (map) => {
        this.setState((state) => ({ ...state, map }));
		this.state.mapRef.current = map;
    }

	setStartDate(date) {
		this.setState({startDate: date})
	}

	setEndDate(date) {
		this.setState({endDate: date})
	}

	handleCenterChange() {
		if (!this.state.mapRef.current) return;
		const newPos = this.state.mapRef.current.getCenter().toJSON();
		console.log(newPos);
		this.setState({newCenter: newPos});
	}
	handleZoomChange() {
		if (!this.state.mapRef.current) return;
		const z = this.state.mapRef.current.getZoom();
		console.log(z);
		this.setState({zoom: z});	
	}
	handleAddThisField() {

		if (this.state.endDate === "" || this.state.startDate === "" || this.state.nickname === "") {
			this.setState({show: true})
			this.setState({modalTitle: "Field left blank"})
			this.setState({modalBody: "Please choose a start date and end date, as well as a field nickname."})
		} else {

			this.setState({showSpinner: true})
			this.setState({spinnerModalBody: "Adding the field to the database..."})

			// Let's make a coords array	   
			var c1 = [this.state.neLong, this.state.swLat]
			var c2 = [this.state.neLong, this.state.neLat]
			var c3 = [this.state.swLong, this.state.neLat]
			var c4 = [this.state.swLong, this.state.swLat]

			// Let's make a mapURL
			var mapUrl = `https://maps.google.com/maps/api/staticmap?sensor=false&center=${this.state.newCenter.lat},${this.state.newCenter.lng}&zoom=${this.state.zoom}&size=400x400&key=${process.env.REACT_APP_MAPS_API_KEY}&maptype=hybrid`

			
			fetch('https://us-central1-hotaru-kanri.cloudfunctions.net/rvm_cloud_add_field', {
				method: 'POST',
				headers: {
					'Content-Type':'application/json'
				},
				body: JSON.stringify({
					username: this.state.username, 
					nickname: this.state.nickname, 
					startDate: this.state.startDate,
					endDate: this.state.endDate,
					one: c1,
					two: c2,
					three: c3,
					four: c4,
					mapUrl: mapUrl
				 })
			}).then(response => response.json())
			.then(response => {
				this.setState({showSpinner: false})
				ReactDOM.render(
					<MyFields 
						visualData={response.visualData} 
						chartDates={response.chartDates}
						chartScores={response.chartScores} 
						inProgress={response.inProgress}
						visibleNow={true} 
						modalTitle={"Field added"} 
						modalBody={"Field has been added. Colored NDVI overlays are being generated now. The process may take anywhere from 10 minutes to an hour. Once the process is finished, you will receive a notification email."}
						username={this.state.username}
						nicknames={response.nicknames}
					/>,
						document.getElementById('root')
				);
				// Now send a post request to "crawl again", which will continue crawling until all inProgress are done
				fetch('https://us-central1-hotaru-kanri.cloudfunctions.net/rvm_cloud_crawl_again', {
					method: 'POST',
					headers: {
						'Content-Type':'application/json'
					},
					body: JSON.stringify({
						lets: "lets", 
						go: "go", 
					})
				})
			})
		}
	}
	


    render () {
		
      return (
		<Container>
			<Modal show={this.state.show} onHide={this.handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.modalTitle}</Modal.Title>
				</Modal.Header>
					<Modal.Body>
						{this.state.modalBody}
					</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={this.state.showSpinner} style={{ content: {borderRadius: '10px'}}}>
					<Modal.Body>
						<Container>
							<Row style={{margin: 10}}>
								<Col>
									<div style={{display: 'flex', justifyContent: 'center'}}>
										{this.state.spinnerModalBody}
									</div>
								</Col>
							</Row>
							<Row style={{margin: 10, padding: 10}}>
								<Col>
									<div style={{display: 'flex', justifyContent: 'center'}}>
										<CircularProgress size={30} />	
									</div>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			<Row style={{ marginTop: 5, marginBottom: 5}}>
				<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
					<Card className="field-card" style={{ width: 500 }}>
						<span className="title-class">Enter an address:</span>
						<FormControl value={this.state.address} onChange={this.handleAddressChange}/>
					</Card>
				</Col>
			</Row>
			<Row style={{ marginTop: 5, marginBottom: 5}}>
				<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
					<button className="add-logout-button" onClick={this.handleFindAddress}>
						Find Address
					</button>
				</Col>
			</Row>
			<Row style={{ marginTop: 5, marginBottom: 5}}>
				<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
					<Card className="field-card">
						<LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY}>
						<GoogleMap
							mapContainerStyle={containerStyle}
							center={this.state.center}
							zoom={10}
							onBoundsChanged={e => { const {map} = this.state; 
													let neLat = map.getBounds().getNorthEast().lat();
													let neLong = map.getBounds().getNorthEast().lng();
													let swLat = map.getBounds().getSouthWest().lat();
													let swLong = map.getBounds().getSouthWest().lng();
													this.setState({neLat: neLat});
													this.setState({neLong: neLong});
													this.setState({swLat: swLat});
													this.setState({swLong: swLong});
												}}
							onCenterChanged={this.handleCenterChange}
							onZoomChanged={this.handleZoomChange}
				
							onLoad={this.handleMapLoad}
						>
						</GoogleMap>
					</LoadScript>
					</Card>
				</Col>
			</Row>
			<Row style={{ marginTop: 5, marginBottom: 5}}>
				<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
					<Card className="field-card">
						<span className="title-class">Field nickname:</span>
						<FormControl value={this.state.nickname} onChange={this.handleNicknameChange}/>
						<span className="title-class">Choose the dates between which to monitor this field</span>
						<span className="title-class">Start date: 
						<DatePicker 
						selected={this.state.startDate} 
						onChange={(date) => {
							var today = new Date()
							if ((date > today) || ((today.getYear() - date.getYear()) > 1)) {
								this.setState({show: true})
								this.setState({modalTitle: "Choose a different date"})
								this.setState({modalBody: "Please choose a date between today and the start of the previous year"})
							} else {
								this.setStartDate(date)
							}
						}} /></span>
						<span className="title-class">End date: 
						<DatePicker selected={this.state.endDate} 
						onChange={(date) => {
							var today = new Date()
							if ((date > today) || ((today.getYear() - date.getYear()) > 1)) {
								this.setState({show: true})
								this.setState({modalTitle: "Choose a different date"})
								this.setState({modalBody: "Please choose a date between today and the start of the previous year"})
							} else {
								this.setEndDate(date)
							}
						}} /></span>
					
					</Card>
				</Col>
			</Row>
			<Row style={{ marginTop: 5, marginBottom: 5}}>
			<Col style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
				<button className="add-logout-button" onClick={this.handleAddThisField}>
					Add this field
				</button>
			</Col>
			</Row>
		</Container>		
      )
    }
} export default NewMap;